.navbar-items {
    background: var(--lg-grey);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: fixed;
    width: 100%;
}

.navbar-logo{
    color: var(--site-orange);
    justify-self: start;
    cursor: pointer;
    font-size: 40px;
    user-select: none;
    margin-right: 20px;
}

.nav-menu {
    display: flex;
    grid-template-columns: repeat(4,auto);
    grid-gap: 5px;
    list-style: none;
    text-align: center;
    width: 55vw;
    justify-content: space-around;
}

.nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
}

.nav-links:hover {
    box-shadow: 0 3px 6px white;
    border-radius: 10px;
    transition: all 0.2s ease-out;
    cursor: pointer;
    user-select: none;
}

.fa-bars {
    color: #fff;
}

.nav-links-mobile {
    display:none;
}

.menu-icon {
    display: none;
}

.active {
    color: #ff9000;
    font-weight:600;
}

@media screen and (max-width: 1000px) {
    .nav-menu {
        width: 45vw;
        grid-gap: 0;
    }
}

@media screen and (max-width: 900px) {
    .navbar-items {
        position: fixed;
    }

    .nav-menu {
        display: flex;
        flex-direction: column;
        width:  100%;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.small-active {
        background: var(--lg-grey);
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-links {
        text-align: center;
        padding: 2rem;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: rgb(255, 144, 0, 0.7);
        border-radius: 16px;
    }

    .navbar-logo {
        position: absolute;
        top: 5;
        left: -60;
        font-size: 28px;
        transform: translate(25%, 50%);
        margin-right: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 3;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
    }

    .fa-times {
        color: #fff;
        font-size: 2rem;
    }
} 

@media screen and (max-width: 375px) {
    .nav-menu {
        top: 60px;
    }
    .navbar-logo{
        font-size: 24px;
        top: 10;
        left: -54;
    }
    .navbar-items {
        height: 60px;
    }
    .navbar-logo {
        position: absolute;
        top: 0;
        left: -50;
    }
    .menu-icon {
        position: absolute;
        top: -8;
        right: -5;
    }
}