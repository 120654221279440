.experience-pic-div {
    text-align: center;
    margin-bottom: 20px;
}

.experience-pic {
    width: 100%;
    max-width: 500px;
    border-radius: var(--border-radius-main);
    box-shadow: var(--box-shadow);
}

.wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.accordion {
    width: 100%;
}

.item {
    padding: 10px 20px;
    margin-bottom: 10px;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
}

.title span {
    font-size: 24px;
}

.content {
    max-height: 0;
    overflow: hidden;
    transition: all 1s cubic-bezier(0, 1, 0, 1);
    margin-bottom: 5px;
}

.content.show {
    height: auto;
    max-height: 9999px;
    transition: all 1s cubic-bezier(1, 0, 1, 0);
}

.occupation {
    padding: 16px 0;
}

.occupation h3 {
    margin-bottom: 5px;
}

.occupation ul {
    list-style-type:circle;
    margin-left: 20px;
}

.occupation ul li {
    margin-bottom: 5px;
}

.occupation:not(:last-child) {
    border-bottom: 1px solid var(--site-orange);
}

.more-link {
    text-decoration: none;
    border-radius: 4px;
    background-color: rgb(47, 48, 51, 0.8);/* rgb(47, 48, 51, 0.5); */
    padding: 4px;
    color: white;
}

@media (hover: hover) {
    .more-link:hover {
        box-shadow: var(--box-shadow);
        background-color: var(--site-orange);
        transition: all 0.2s ease-out;
    }
}
@media screen and (min-width: 1151px) {
    .experience-div {
        display: flex;
        width: 1064px;
        margin: auto;
    }
    
    .experience-pic-div {
        margin: 0 10px;
    }

    .experience-pic {
        max-width: 100%;
        position: sticky;
        top: 110;
        z-index: -2; 
    }
    .accordion {
        width: 100%;
        margin: 0 10px;
    }
}