:root {
    --lg-grey: linear-gradient(90deg, rgb(94, 94, 96, 0.5) 0%, rgb(47, 48, 51, 1) 100%);
    --site-orange: #ff9000;
    --box-shadow: 0 3px 6px #888888;
    --padding-main: 30px;
    --border-radius-main: 30px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Montserrat', sans-serif;
}

main {
    margin: auto;
    width: 95%;
    padding: 110px 15px 90px 15px;
}

.error {
    color: red;
    margin-bottom: 10px;
}

@media screen and (max-width: 375px) {
    main {
        padding: 90px 15px 90px 15px
    }
}
