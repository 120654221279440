:root {
  --lg-grey: linear-gradient(90deg, #5e5e6080 0%, #2f3033 100%);
  --site-orange: #ff9000;
  --box-shadow: 0 3px 6px #888;
  --padding-main: 30px;
  --border-radius-main: 30px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
}

main {
  width: 95%;
  margin: auto;
  padding: 110px 15px 90px;
}

.error {
  color: red;
  margin-bottom: 10px;
}

@media screen and (max-width: 375px) {
  main {
    padding: 90px 15px;
  }
}

footer {
  background: var(--lg-grey);
  text-align: center;
  width: 100%;
  color: #fff;
  padding: 6px;
  position: fixed;
  bottom: 0;
  left: 0;
}

.contact-icons > a {
  margin: 0 10px;
  text-decoration: none;
}

.contact-icons {
  text-align: center;
}

.my-icon {
  color: #fff;
  border-radius: 10px;
  margin: 0 10px;
  padding: 2px;
  font-size: 40px;
  transition: transform .1s ease-out;
}

.my-icon:active {
  color: #ff9000;
  transform: scale(1.1);
  box-shadow: 0 3px 6px #fff;
}

@media (hover: hover) {
  .my-icon:hover {
    color: #ff9000;
    transform: scale(1.1);
    box-shadow: 0 3px 6px #fff;
  }
}

@media screen and (max-width: 375px) {
  .my-icon {
    font-size: 38px;
  }
}

.navbar-items {
  background: var(--lg-grey);
  height: 80px;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  display: flex;
  position: fixed;
}

.navbar-logo {
  color: var(--site-orange);
  cursor: pointer;
  user-select: none;
  justify-self: start;
  margin-right: 20px;
  font-size: 40px;
}

.nav-menu {
  grid-gap: 5px;
  text-align: center;
  width: 55vw;
  grid-template-columns: repeat(4, auto);
  justify-content: space-around;
  list-style: none;
  display: flex;
}

.nav-links {
  color: #fff;
  padding: .5rem 1rem;
  text-decoration: none;
}

.nav-links:hover {
  cursor: pointer;
  user-select: none;
  border-radius: 10px;
  transition: all .2s ease-out;
  box-shadow: 0 3px 6px #fff;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile, .menu-icon {
  display: none;
}

.active {
  color: #ff9000;
  font-weight: 600;
}

@media screen and (max-width: 1000px) {
  .nav-menu {
    width: 45vw;
    grid-gap: 0;
  }
}

@media screen and (max-width: 900px) {
  .navbar-items {
    position: fixed;
  }

  .nav-menu {
    width: 100%;
    opacity: 1;
    flex-direction: column;
    transition: all .5s;
    display: flex;
    position: absolute;
    top: 80px;
    left: -100%;
  }

  .nav-menu.small-active {
    background: var(--lg-grey);
    opacity: 1;
    z-index: 1;
    transition: all .5s;
    left: 0;
  }

  .nav-links {
    text-align: center;
    width: 100%;
    padding: 2rem;
    display: table;
  }

  .nav-links:hover {
    background-color: #ff9000b3;
    border-radius: 16px;
  }

  .navbar-logo {
    margin-right: 0;
    font-size: 28px;
    position: absolute;
    top: 5px;
    left: -60px;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    cursor: pointer;
    font-size: 1.8rem;
    display: block;
    position: absolute;
    top: 3px;
    right: 0;
    transform: translate(-100%, 60%);
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }
}

@media screen and (max-width: 375px) {
  .nav-menu {
    top: 60px;
  }

  .navbar-logo {
    font-size: 24px;
    top: 10px;
    left: -54px;
  }

  .navbar-items {
    height: 60px;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: -50px;
  }

  .menu-icon {
    position: absolute;
    top: -8px;
    right: -5px;
  }
}

.home-pic-div {
  text-align: center;
  margin-bottom: 20px;
}

.home-pic {
  width: 100%;
  max-width: 500px;
  border-radius: var(--border-radius-main);
  box-shadow: var(--box-shadow);
  text-align: center;
}

.about {
  max-width: 750px;
  margin: auto;
}

.about-short {
  width: 100%;
  box-shadow: var(--box-shadow);
  border-radius: 8px;
  padding: 20px;
}

.about-short > h2 {
  text-align: center;
  margin-bottom: 15px;
}

.topic {
  padding: 20px 0;
}

.topic > h4 {
  margin-bottom: 10px;
  font-size: 20px;
}

.topic:not(:last-child) {
  border-bottom: 1px solid var(--site-orange);
}

.skills {
  flex-wrap: wrap;
  display: flex;
}

.skill {
  border-radius: 8px;
  margin: 4px;
  padding: 6px;
  box-shadow: 0 2px 4px #888;
}

.about-more {
  width: 100%;
  box-shadow: var(--box-shadow);
  border-radius: 8px;
  margin-top: 20px;
  padding: 20px;
}

.about-more-content {
  max-height: 0;
  transition: all 1s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
}

.about-more-content.show {
  height: auto;
  max-height: 9999px;
  transition: all 1s cubic-bezier(1, 0, 1, 0);
}

.about-more-title {
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.about-more-title span {
  font-size: 24px;
}

.about-more p {
  margin-bottom: 10px;
}

.about-more p:first-child {
  margin-top: 15px;
}

@media screen and (min-width: 900px) {
  .home-div {
    max-width: 1200px;
    margin: auto;
    display: flex;
  }

  .home-pic {
    width: 100%;
    max-width: none;
    border-radius: var(--border-radius-main);
    box-shadow: var(--box-shadow);
    z-index: -1;
    position: sticky;
    top: 110px;
  }

  .home-pic-div {
    width: 50%;
    max-width: 550px;
    justify-content: right;
    margin-right: 20px;
  }

  .about {
    width: 50%;
    margin: auto auto 20px;
  }
}

.experience-pic-div {
  text-align: center;
  margin-bottom: 20px;
}

.experience-pic {
  width: 100%;
  max-width: 500px;
  border-radius: var(--border-radius-main);
  box-shadow: var(--box-shadow);
}

.wrapper {
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.accordion {
  width: 100%;
}

.item {
  box-shadow: var(--box-shadow);
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 10px 20px;
}

.title {
  cursor: pointer;
  user-select: none;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.title span {
  font-size: 24px;
}

.content {
  max-height: 0;
  margin-bottom: 5px;
  transition: all 1s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;
}

.content.show {
  height: auto;
  max-height: 9999px;
  transition: all 1s cubic-bezier(1, 0, 1, 0);
}

.occupation {
  padding: 16px 0;
}

.occupation h3 {
  margin-bottom: 5px;
}

.occupation ul {
  margin-left: 20px;
  list-style-type: circle;
}

.occupation ul li {
  margin-bottom: 5px;
}

.occupation:not(:last-child) {
  border-bottom: 1px solid var(--site-orange);
}

.more-link {
  color: #fff;
  background-color: #2f3033cc;
  border-radius: 4px;
  padding: 4px;
  text-decoration: none;
}

@media (hover: hover) {
  .more-link:hover {
    box-shadow: var(--box-shadow);
    background-color: var(--site-orange);
    transition: all .2s ease-out;
  }
}

@media screen and (min-width: 1151px) {
  .experience-div {
    width: 1064px;
    margin: auto;
    display: flex;
  }

  .experience-pic-div {
    margin: 0 10px;
  }

  .experience-pic {
    max-width: 100%;
    z-index: -2;
    position: sticky;
    top: 110px;
  }

  .accordion {
    width: 100%;
    margin: 0 10px;
  }
}

.projects {
  width: 100%;
  height: auto;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.project-item {
  height: 300px;
  width: 300px;
  text-align: center;
  border-radius: 15px;
  margin: 40px 10px;
  box-shadow: 0 3px 15px #0003;
}

.projects .projects-title {
  text-align: center;
  font-size: 40px;
  font-weight: bolder;
}

.project-item .bg-image {
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.bg-image {
  border-bottom: 1px solid #e2e2e2;
}

.project-item:hover {
  cursor: pointer;
  transition: all .3s ease-in;
  box-shadow: 0 3px 15px #00000080;
}

.project-name {
  height: 100px;
  position: relative;
}

.project-name h2 {
  margin: 0 -50% 0 0;
  padding: 20px;
  font-size: 22px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.project-list {
  height: auto;
  place-items: center;
}

@media screen and (min-width: 760px) {
  .project-list {
    flex-wrap: wrap;
    justify-content: space-around;
    display: flex;
  }
}

@media screen and (min-width: 1240px) {
  .projects {
    max-width: 1140px;
    margin: auto;
  }
}

.project-image-div {
  text-align: center;
}

.project img {
  width: 100%;
  max-width: 600px;
  border-radius: 30px;
}

.project h1 {
  text-align: center;
  margin: 0 0 10px;
}

.project p {
  border-bottom: 1px solid #ff9000;
  padding: 20px 0;
}

.gh-div {
  text-align: center;
}

.gh-icon {
  color: #000;
  text-align: center;
  border-radius: 10px;
  margin: 30px;
  padding: 2px;
  font-size: 60px;
  transition: transform .1s ease-out;
}

.gh-icon:active {
  color: #ff9000;
  transform: scale(1.1);
  box-shadow: 0 3px 6px #888;
}

.live-url-button {
  color: #fff;
  background-color: #2f3033cc;
  border-radius: 4px;
  padding: 4px;
  text-decoration: none;
}

@media (hover: hover) {
  .gh-icon:hover {
    color: #ff9000;
    transform: scale(1.1);
    box-shadow: 0 3px 6px #888;
  }

  .live-url-button:hover {
    box-shadow: var(--box-shadow);
    background-color: var(--site-orange);
    transition: all .2s ease-out;
  }
}

@media screen and (min-width: 1130px) {
  .project {
    width: 1064px;
    margin: auto;
  }
}

@media screen and (max-width: 400px) {
  .project h1 {
    font-size: 24px;
  }
}

.contact-pic-div {
  text-align: center;
  margin-bottom: 20px;
}

.contact-pic {
  width: 100%;
  max-width: 500px;
  border-radius: var(--border-radius-main);
  box-shadow: var(--box-shadow);
}

.contact-form-div {
  width: 100%;
  box-shadow: var(--box-shadow);
  border-radius: 8px;
  padding: 20px;
}

.contact-form-div h1 {
  text-align: center;
  margin-bottom: 20px;
}

.email-form > input, .email-form textarea {
  width: 100%;
  border: 2px solid #ff9000;
  border-radius: 6px;
  margin: 5px 0 10px;
  padding: 10px;
  box-shadow: 0 0 2px 1px;
}

.email-form textarea {
  height: 100px;
  box-sizing: border-box;
  resize: vertical;
}

.email-form > label {
  font-size: 20px;
}

.send-button {
  color: #fff;
  cursor: pointer;
  background-color: #2f3033cc;
  border: none;
  border-radius: 4px;
  padding: 4px;
  font-size: 20px;
  text-decoration: none;
}

@media (hover: hover) {
  .send-button:hover {
    box-shadow: var(--box-shadow);
    background-color: var(--site-orange);
    transition: all .2s ease-out;
  }
}

.message-sent {
  margin-top: 20px;
}

@media screen and (min-width: 1151px) {
  .contact {
    width: 1064px;
    margin: auto;
    display: flex;
  }

  .contact-pic-div, .contact-form-div {
    margin: 0 10px;
  }

  .contact-pic {
    width: 700px;
  }

  .email-form textarea {
    height: 290px;
  }
}

/*# sourceMappingURL=index.501c11a8.css.map */
