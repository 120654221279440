.contact-pic-div {
    text-align: center;
    margin-bottom: 20px;
}
.contact-pic{
    width: 100%;
    max-width: 500px;
    border-radius: var(--border-radius-main);
    box-shadow: var(--box-shadow);
}

.contact-form-div {
    width: 100%;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    padding: 20px;
}

.contact-form-div h1 {
    text-align: center;
    margin-bottom: 20px;
}

.email-form > input, .email-form textarea {
    padding: 10px;
    box-shadow: 0 0 2px 1px;
    border-radius: 6px;
    margin: 5px 0px 10px 0px;
    border: 2px solid #ff9000;
    width: 100%;
}
.email-form textarea {
    height: 100px;
    box-sizing: border-box;
    resize: vertical
}

.email-form > label {
    font-size: 20px;
}

.send-button{
    text-decoration: none;
    border: none;
    border-radius: 4px;
    background-color: rgb(47, 48, 51, 0.8);/* rgb(47, 48, 51, 0.5); */
    padding: 4px;
    color: white;
    cursor: pointer;
    font-size: 20px;
}

@media (hover: hover) {
    .send-button:hover {
        box-shadow: var(--box-shadow);
        background-color: var(--site-orange);
        transition: all 0.2s ease-out;
    }
}

.message-sent {
    margin-top: 20px;
}

@media screen and (min-width: 1151px){
    .contact {
        display: flex;
        margin: auto;
        width: 1064px;
    }

    .contact-pic-div, .contact-form-div {
        margin: 0 10px;
    }

    .contact-pic {
        width: 700px;
    }
    .email-form textarea {
        height: 290px;
    }
}