.projects {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.project-item {
    height: 300px;
    width: 300px;
    border-radius: 15px;
    margin: 40px 10px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.projects .projects-title {
    text-align: center;
    font-size: 40px;
    font-weight: bolder;
}

.project-item .bg-image {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.bg-image {
    border-bottom: 1px solid rgb(226, 226, 226);
}

.project-item:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
}

.project-name {
    height: 100px;
    position: relative;
}

.project-name h2 {
    font-size: 22px;
    display: block;
    padding: 20px;
    font-weight: bold;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.project-list {
    height: auto;
    place-items: center;
}

@media screen and (min-width: 760px) {
    .project-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
}

@media screen and (min-width: 1240px) {
    .projects {
        max-width: 1140px;
        margin: auto
    }
}