.home-pic-div {
    text-align: center;
    margin-bottom: 20px;
}
.home-pic{
    width: 100%;
    max-width: 500px;
    border-radius: var(--border-radius-main);
    box-shadow: var(--box-shadow);
    text-align: center;
}

.about {
    max-width: 750px;
    margin: auto;
}

.about-short {
    width: 100%;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    padding: 20px;
}

.about-short > h2 {
    margin-bottom: 15px;
    text-align: center;
}

.topic {
    padding: 20px 0px;
}

.topic > h4 {
    margin-bottom: 10px;
    font-size: 20px;
}

.topic:not(:last-child) {
    border-bottom: 1px solid var(--site-orange);
}


.skills {
    display: flex;
    flex-wrap: wrap;
}

.skill {
    box-shadow: 0 2px 4px #888888;
    padding: 6px;
    border-radius: 8px;
    margin: 4px;
}

.about-more {
    width: 100%;
    box-shadow: var(--box-shadow);
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
}

.about-more-content {
    max-height: 0;
    overflow: hidden;
    transition: all 1s cubic-bezier(0, 1, 0, 1);
}

.about-more-content.show {
    height: auto;
    max-height: 9999px;
    transition: all 1s cubic-bezier(1, 0, 1, 0);
}

.about-more-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    user-select: none;
}
.about-more-title span {
    font-size: 24px;
}

.about-more p {
    margin-bottom: 10px;
}

.about-more p:first-child {
    margin-top: 15px;
}

@media screen and (min-width: 900px) {
    .home-div {
        display: flex;
        margin: auto;
        max-width: 1200px;
    }
    .home-pic{
        width: 100%;
        max-width: none;
        border-radius: var(--border-radius-main);
        box-shadow: var(--box-shadow);
        position: sticky;
        top: 110;
        z-index: -1;
    }

    .home-pic-div {
        width: 50%;
        margin-right: 20px;
        max-width: 550px;
        justify-content: right;
    }
    
    .about {
        width: 50%;
        margin: auto;
        margin-bottom: 20px;
    }
}